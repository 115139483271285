<template>
  <div class="layout-padding row justify-center" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">Submitted Content</div>
        <div class="text-body2 q-mt-lg">Submit your own video to be reviewed by your site administrator</div>
      </div>

      <div class="row q-my-xl">
        <div class="row full-width justify-center">
          <q-btn style="width: 500px;" color="primary" @click="showCreateModal = true">Submit Content</q-btn>
        </div>
      </div>

      <div class="row">
        <div class="row q-my-md full-width">
          <div class="text-body1">Submissions</div>
        </div>
        <div class="row full-width">
          <q-list class="full-width" v-if="submittedContent.length">
            <q-item class="text-subtitle2 text-bold">
              <q-item-section class="col-4">Video Name</q-item-section>
              <q-item-section class="col-4">User</q-item-section>
              <q-item-section class="col-4">Actions</q-item-section>
            </q-item>
            <q-item v-for="content in submittedContent" :key="content.uid" class="row full-width">
              <q-item-section class="col-4">
                {{ content.name }}
              </q-item-section>
              <q-item-section class="col-4">
                {{ content.owner_name}}
              </q-item-section>
              <q-item-section side class="primaryText-text">
                <div v-if="content.removedByAdmin">Removed By Admin</div>
                <q-btn v-else flat round icon="get_app" @click="downloadVideo(content.path)">
                  <q-tooltip>Download Video</q-tooltip>
                </q-btn>
              </q-item-section>
              <q-item-section side class="primaryText-text q-mx-xl">
                <q-btn flat round icon="delete" @click="deleteVideo(content)">
                  <q-tooltip>Remove Video</q-tooltip>
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
          <div v-else class="row full-width justify-center q-mt-xl">
            <div class="text-subtitle1">Currently No Submissions</div>
          </div>
        </div>
      </div>

      <q-dialog v-model="showCreateModal" @hide="upload = { name: null, file: null }">
        <q-card style="width: 500px" class="bg-lmsBackground primaryText-text">
          <q-card-section>
            <div class="text-h5">Submit Content</div>
          </q-card-section>
          <q-card-section>
            <q-form @submit="submitUpload" @reset="showCreateModal = false" autocomplete="off">
              <q-input v-model="upload.name" label="Video Name" />
              <div class="q-mt-xl">
                <div class="text-caption">Max File Size: 1GB</div>
                <input id="fileUpload" @change="uploadFile" accept="video/*,image/*,.pdf" type="file">
                <div class="text-body2" v-if="fileTooLarge">*File exceeds max size allowed</div>
              </div>
              <div class="row justify-end gutter-xs q-my-lg q-mx-lg">
                <div class="col-auto q-mt-lg self-center">
                  <q-btn type="reset" flat class="q-btn-flat q-mx-sm">Cancel</q-btn>
                  <q-btn type="submit" color="primary" :disable="fileTooLarge">Submit</q-btn>
                </div>
              </div>
            </q-form>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SubmittedContent',
  data () {
    return {
      maxFileSize: 1000000000, // 1GB
      fileTooLarge: false,
      showCreateModal: false,
      submittedContent: [],
      upload: {
        name: null,
        file: null
      }
    }
  },
  methods: {
    isOwner (ownerUid) {
      return ownerUid === this.$store.getters['auth/user'].uid
    },
    uploadFile (e) {
      if (e.target.files[0].size > this.maxFileSize) {
        this.fileTooLarge = true
      } else {
        this.fileTooLarge = false
        this.upload.file = document.querySelector('#fileUpload').files[0]
      }
    },
    async submitUpload () {
      this.saving = true
      let fd = new FormData()
      fd.append('name', this.upload.name)
      fd.append('file', this.upload.file)

      try {
        this.$loading(true, 'Uploading Content')
        let res = await this.$axios.post(this.$consts.STORE_SUBMITTED_CONTENT_URL, fd)
        this.submittedContent.push(res.data)
        this.showCreateModal = false
        this.$loading(false)
        this.$successMsg()
      } catch (e) {
        this.$loading(false)
        this.$failureMsg()
      }
    },
    downloadVideo (url) {
      window.open(url, '_blank')
    },
    async deleteVideo (content) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete '${content.name}' submitted by ${content.owner_name}?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(async () => {
        try {
          this.$loading(true, 'Deleting Video')
          await this.$axios.delete(`${this.$consts.DELETE_SUBMITTED_CONTENT_URL}/${content.uid}`)
          this.$store.dispatch('submittedContent/fetchCount')
          this.submittedContent = this.submittedContent.filter(video => video.uid !== content.uid)
          this.$loading(false)
          this.$successMsg('Video Deleted')
        } catch (e) {
          this.$loading(false)
          this.$failureMsg('Sorry something went wrong trying to delete')
        }
      })
    }
  },
  async created () {
    try {
      this.$loading()
      let res = await this.$axios.get(this.$consts.GET_SUBMITTED_CONTENT_URL)
      this.$store.dispatch('submittedContent/fetchCount')
      this.submittedContent = res.data
      this.$loading(false)
    } catch (e) {
      this.$loading(false)
      this.$failureMsg()
    }
  }
}
</script>
